import { faCrystalBall } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { Tooltip } from 'react-tippy'

export default function NoneActiveShrineIcon () {
  return (
    <Tooltip title="You don't have any Shrine activated!">
      <Link href="/shrines">
        <FontAwesomeIcon
          icon={faCrystalBall}
          className="text-failure w-5 h-5 mt-0.5 mr-0.5"
        />
      </Link>
    </Tooltip>
  )
}
