import { useWindowSize } from 'hooks/useWindowSize'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenTab } from 'store'

export default function TabNav () {
  const user = useSelector(store => store.main.auth.user)
  const openTab = useSelector(store => store.main.tabs.open)
  const disptach = useDispatch()
  const router = useRouter()
  const size = useWindowSize()
  const changeTab = slug => {
    disptach(setOpenTab(slug))
  }

  let tabs = []

  if (router.asPath.startsWith('/tavern') && !router.asPath.startsWith('/tavern/grand-chest') && size.width < 1024) {
    tabs = [
      {
        slug: 'quests',
        label: 'Quests'
      },
      {
        slug: 'raids',
        label: 'Raids'
      },
      {
        slug: 'news',
        label: 'News',
        updates: user && user.hasUnreadNews
      },
      {
        slug: '/tavern/grand-chest',
        label: 'Chest',
        link: true
      }
    ]
  } else if (router.asPath.startsWith('/account/transactions')) {
    tabs = [
      {
        slug: '/account/transactions',
        label: 'Frags',
        link: true
      },
      {
        slug: '/account/transactions/orbs',
        label: 'Orbs',
        link: true
      },
      {
        slug: '/account/transactions/shop',
        label: 'Shop',
        link: true
      },
      {
        slug: '/account/transactions/crafting',
        label: 'Crafts',
        link: true
      },
      {
        slug: '/account/transactions/shrine-upgrades',
        label: 'Shrines',
        link: true
      }
    ]
  } else if ((router.asPath.startsWith('/tavern') && size.width >= 1024) || router.asPath === '/tavern/grand-chest' || router.asPath === '/shrines') {
    tabs = [
      {
        slug: '/tavern',
        label: 'Tavern',
        link: true
      },
      {
        slug: '/shrines',
        label: 'Shrines',
        link: true
      },
      {
        slug: '/tavern/grand-chest',
        label: 'Grand Chest',
        link: true
      }
    ]
  } else if (router.asPath.startsWith('/account/achievements') || router.asPath.startsWith('/leaderboard')) {
    tabs = [
      {
        slug: '/leaderboard/tavern-brawls',
        label: 'Brawls',
        link: true
      },
      {
        slug: '/leaderboard',
        label: 'Leaderboard',
        link: true
      },
      {
        slug: '/account/achievements',
        label: 'Achievements',
        link: true
      }
    ]
  } else if (router.asPath.startsWith('/account/wallets') || router.asPath.startsWith('/account/verify-wallet') || router.asPath.startsWith('/account/preferences') || router.asPath.startsWith('/account/profile')) {
    tabs = [
      {
        slug: '/account/preferences',
        label: 'Preferences',
        link: true
      },
      {
        slug: '/account/wallets',
        label: 'Wallets',
        link: true,
        active: router.asPath.includes('wallet')
      },
      {
        slug: '/account/profile',
        label: 'Profile',
        link: true,
        active: router.asPath.includes('profile')
      }
    ]
  } else if (router.asPath.startsWith('/account/passive-staking') || router.asPath.startsWith('/account/partner-staking')) {
    tabs = [
      {
        slug: '/account/passive-staking',
        label: 'Adventurers',
        link: true
      },
      {
        slug: '/account/passive-staking/shrines',
        label: 'Shrines',
        link: true
      },
      {
        slug: '/account/partner-staking',
        label: 'Partner projects',
        link: true
      }
    ]
  } else if (router.asPath.startsWith('/account/adventurers') || router.asPath.startsWith('/account/shrines')) {
    tabs = [
      {
        slug: '/account/adventurers',
        label: 'Adventurers',
        link: true
      },
      {
        slug: '/account/shrines',
        label: 'Shrines',
        link: true
      }
    ]
  }

  if (!tabs || !tabs.length) {
    return <></>
  }

  return (
    <div className="flex justify-center fixed bottom-14 left-2 right-2 z-20 lg:absolute lg:bottom-6">
      <ul className="rounded bg-raids-darker flex drop-shadow-2xl">
        {tabs.map(tab => {
          const update = tab.updates ? <div className="absolute right-0.5 top-0.5 bg-failure rounded-full w-3 h-3"></div> : ''

          return (
            <li key={tab.slug}>
              {tab.link && (
                <Link
                  href={tab.slug}
                  className={`relative block rounded p-2 px-2.5 uppercase font-bold lg:p-4 lg:px-8 ${router.asPath === tab.slug || tab.active ? 'bg-primary' : ''}`}
                >
                  {tab.label}
                  {update}
                </Link>
              )}

              {!tab.link && (
                <button
                  type="button"
                  onClick={() => changeTab(tab.slug)}
                  className={`relative block rounded p-2 px-2.5 uppercase font-bold lg:p-4 lg:px-8 ${openTab === tab.slug ? 'bg-primary' : ''}`}
                >
                  {tab.label}
                  {update}
                </button>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
