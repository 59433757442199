export default function Snow ({ large, width, top, left }) {
  return (
    <span
      className="pointer-events-none absolute"
      style={{
        width,
        top: top ?? -8,
        left: left ? 0 : 'auto',
        right: left ? 'auto' : 4
      }}
    >
      <img
        src={`/holidays/snow-${large ? 'large' : 'small'}.png`}
        alt="Snow"
        className="h-auto"
        style={{
          width
        }}
      />
    </span>
  )
}
