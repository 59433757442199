import 'react-tippy/dist/tippy.css'
import 'react-toastify/dist/ReactToastify.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import '../styles/globals.css'
import Head from 'next/head'
import Script from 'next/script'
import { DefaultSeo } from 'next-seo'
import { Provider } from 'react-redux'
import CookieConsent from 'react-cookie-consent'
import store from '../store'
import Layout from '../components/Layout'
import MobileNav from '../components/layout/MobileNav'
import { ToastContainer } from 'react-toastify'
import AuthenticateUser from 'components/AuthenticateUser'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import VersionCheck from 'components/version/VersionCheck'

function MyApp ({ Component, pageProps, router }) {
  const queryClient = new QueryClient()

  return (
    <Provider store={store}>
      <Script
        async
        src='https://www.googletagmanager.com/gtag/js?id=G-JBN6YK9KDN'
      />
      <AuthenticateUser />
      <Script id='ga'>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-JBN6YK9KDN');`}
      </Script>

      <Head>
        <link
          rel='icon'
          href='/favicon.ico'
        />
      </Head>

      <DefaultSeo
        description='Welcome to the Tavern Squad. A unique fantasy themed CNFT collection.'
        openGraph={{
          type: 'website',
          locale: 'en_US',
          url: 'https://tavernsquad.io',
          site_name: 'Tavern Squad',
          images: [
            {
              url: 'https://tavernsquad.io/og.jpg',
              width: 1200,
              height: 630,
              alt: 'Tavern Squad',
              type: 'image/jpeg'
            }
          ]
        }}
        twitter={{
          handle: '@TavernSquadNFT',
          site: '@site',
          cardType: 'summary_large_image'
        }}
      />

      <Layout>
        <QueryClientProvider client={queryClient}>
          <Component {...pageProps} />
        </QueryClientProvider>
      </Layout>

      <CookieConsent
        style={{ background: 'none' }}
        buttonText='Fine by me!'
        buttonStyle={{
          background: 'var(--primary-color)',
          position: 'relative',
          zindex: 10
        }}
      >
        <div style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: 'var(--tertiary-color)', opacity: 0.9, zIndex: -100 }}/>
        <div style={{ opacity: 0.9 }}>
          This website uses cookies to enhance the user experience.
        </div>
      </CookieConsent>
      <VersionCheck />
      <MobileNav />
      <ToastContainer
        position='bottom-right'
        theme='dark'
      />
    </Provider>
  )
}

export default MyApp
