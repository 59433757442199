import Link from 'next/link'
import { useRouter } from 'next/router'
import { faBeerFoam, faBuildingColumns, faCrystalBall, faGear, faHammerCrash, faHatWizard, faRightToBracket, faStore, faTrophy } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarsSort, faClose, faList } from '@fortawesome/pro-solid-svg-icons'
import { useState, useEffect } from 'react'
import NavListItem from 'components/layout/NavListItem'
import { useSelector } from 'react-redux'

export default function Nav () {
  const user = useSelector(store => store.main.auth.user)
  const router = useRouter()
  const [showExtraMenu, setShowExtraMenu] = useState(false)

  const toggleExtraMenu = () => {
    setShowExtraMenu(!showExtraMenu)
  }

  useEffect(() => {
    const handleRouteChange = () => {
      setShowExtraMenu(false)
    }

    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  const links = [
    {
      href: '/tavern',
      label: 'Tavern',
      icon: faBeerFoam,
      updates: user && user.hasUnreadNews
    },
    {
      href: '/leaderboard/tavern-brawls',
      label: 'Hall of Fame',
      icon: faTrophy,
      active: router.asPath.startsWith('/leaderboard') || router.asPath === '/account/achievements'
    },
    {
      href: '/raids/available',
      label: 'Raids',
      image: '/icons/swords.png'
    },
    {
      href: '/shrines',
      label: 'Shrine',
      icon: faCrystalBall
    },
    {
      onClick: toggleExtraMenu,
      label: 'Settings',
      icon: faBarsSort,
      active: showExtraMenu || router.asPath === '/raids/transactions' || router.asPath === '/account/preferences' || router.asPath === '/account/wallets'
    }
  ]

  const moreLinks = [
    {
      href: '/account/adventurers',
      label: 'Inventory',
      icon: faHatWizard
    },
    {
      label: 'Crafting',
      icon: faHammerCrash,
      href: '/raids/craft'
    },
    {
      label: 'Shop',
      icon: faStore,
      href: '/raids/shop'
    },
    {
      label: 'Passive staking',
      icon: faBuildingColumns,
      href: '/account/passive-staking'
    },
    {
      label: 'Transactions',
      icon: faList,
      href: '/account/transactions'
    },
    {
      label: 'Settings',
      icon: faGear,
      href: '/account/preferences'
    },
    {
      label: 'Logout',
      icon: faRightToBracket,
      href: '/logout'
    }
  ]

  return (
    <>
      <div className="fixed bottom-0 left-0 w-full bg-raids-darker drop-shadow lg:hidden z-20">
        <div className="relative flex justify-center border-r border-tertiary-light mx-auto">
          <div className="flex justify-evenly space-x-1 items-center w-full">
            {links.map(link => (
              <div
                key={link.href ?? link.label}
                className="w-full"
              >
                {link.href && (
                  <Link
                    href={link.href}
                    legacyBehavior
                  >
                    <a className={`flex justify-center relative p-2 py-[0.75rem] w-full rounded ${new RegExp(`^${link.href}`).test(router.asPath) || link.active ? 'bg-primary' : ''}`}>
                      {link.icon && (
                      <FontAwesomeIcon
                        icon={link.icon}
                        className="w-6 h-6 block"
                      />
                      )}
                      {link.image && (
                      <img
                        src={link.image}
                        alt={link.label}
                        width={60}
                        height={60}
                        className="h-6 w-auto block"
                      />
                      )}
                      {link.updates && (
                      <span className="absolute top-3 right-3 bg-failure rounded-full w-3 h-3"></span>
                      )}
                    </a>
                  </Link>
                )}

                {!link.href && (
                  <button
                    key={link.href}
                    type="button"
                    onClick={link.onClick}
                    className={`flex justify-center relative p-2 py-[0.75rem] w-full rounded ${new RegExp(`^${link.href}`).test(router.asPath) || link.active ? 'bg-primary' : ''}`}
                  >
                    <FontAwesomeIcon
                      icon={link.icon}
                      className="w-6 h-6 block"
                    />
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {showExtraMenu && (
      <div className="fixed z-20 bg-raids-dark top-0 right-0 bottom-[48px] left-0 lg:hidden">
        <div className="fixed z-20 bottom-[48px] right-0 rounded-tl bg-raids-darker p-3">
          <div className="flex justify-end w-full">
            <button
              type="button"
              onClick={toggleExtraMenu}
              className="flex items-center text-sm"
            >
              <FontAwesomeIcon
                icon={faClose}
                className="w-4 h-4 mr-1 text-white"
              />
              <span>
                close
              </span>
            </button>
          </div>
          <ul className="py-3 pb-2">
            {moreLinks.map(link => {
              return (
                <NavListItem
                  link={link}
                  key={link.href}
                />
              )
            })}
          </ul>
        </div>
      </div>
      )}
    </>
  )
}
