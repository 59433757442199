import useAuth from 'hooks/useAuth'
import Stat from './Stat'
import UserGem from './UserGem'
import ProgressBar from '@atoms/ProgressBar'
import { formatNumber } from 'accounting'
import { useDispatch, useSelector } from 'react-redux'
import BuffIcon from './BuffIcon'
import { getLucid } from 'helpers/lucid'
import { getWalletAssets } from 'helpers/raidsTx'
import { connectWallet } from 'redux/raids'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh, faTrophy } from '@fortawesome/pro-duotone-svg-icons'
import { useState } from 'react'
import { useRouter } from 'next/router'
import Card from './Card'
import WalletSelect from './WalletSelect'
import Link from 'next/link'
import UserBar from 'components/account/UserBar'
import Notifications from 'components/account/Notifications'
import { Tooltip } from 'react-tippy'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import Snow from 'components/layout/holidays/Snow'
import NoneActiveShrineIcon from 'components/account/NoneActiveShrineIcon'
import AudioPlayer from 'components/music/AudioPlayer'

export default function StatBar () {
  const [loading, setLoading] = useState(false)
  const user = useAuth()
  const walletData = useSelector(store => store.raids.wallet)
  const selectedWallet = useSelector(store => store.raids.selectedWallet)
  const dispatch = useDispatch()
  const router = useRouter()
  const isRaidsDetailPage = router.asPath.startsWith('/raids/me/') || router.asPath.startsWith('/raids/available/')
  const fragmentsTooltip = '<strong>Wyrmstone Fragments</strong> is the off-chain version of Wyrmstone and the main currency of Tavern Squad and Dungeon Raids. You need it to start raids and earn it by completing raids and looting the grand chest. In the future <strong>Fragments</strong> will be used as a spender for professions, upgrades and other parts of the ecosystem. You can craft <strong>Fragments</strong> into Wyrmstone to take full ownership and receive them in your Cardano wallet.'
  const wyrmstoneTooltip = '<strong>Wyrmstone</strong> or <strong>$WYRM</strong> is the on-chain version of Wyrmstone Fragments. You can craft Fragments into <strong>Wyrmstone</strong> to take full ownership and receive them in your Cardano wallet. Or craft <strong>Wyrmstone</strong> back to Fragments if you need them in-game.'
  const orbsTooltip = '<strong>Orbs</strong> are off-chain and used to unlock raid slots, raid depths and to purchase account wide buffs. Orbs are purchased with ADA in the shop. Orbs allow players to play and progress through Dungeon Raids without the need to make blockchain transaction, also on mobile!'

  const refreshWallet = async () => {
    if (loading) {
      return
    }

    setLoading(true)
    try {
      const lucid = await getLucid()
      const assets = await getWalletAssets(lucid)
      const address = await lucid.wallet.address()
      dispatch(connectWallet({
        wallet: selectedWallet,
        address,
        ...assets
      }))
    } catch (e) {

    }
    setLoading(false)
  }

  if (!user) {
    return (
      <Card
        className="w-full h-18 relative tombstone"
      />
    )
  }

  return (
    <>
      <div className="w-full p-2 rounded bg-dark lg:hidden relative">
        <div className="flex-wrap flex w-full items-center">
          <div className="mr-4">
            {user && (
            <div className="relative rounded-sm p-2 pl-14 pr-7 text-sm bg-tertiary leading-4">
              <div className="flex items-center">
                <Link href={'/leaderboard'}>
                  <span>
                    {user?.leaderboard_position}
                  </span>
                  <FontAwesomeIcon
                    icon={faTrophy}
                    className="w-5 h-5 absolute top-1/2 -translate-y-1/2 right-1 text-primary"
                  />
                </Link>
              </div>
              <div className="absolute left-0 top-1/2 -translate-y-1/2">
                <Link href={`/profiles/${user.discord_id}`}>
                  <UserGem user={user} />
                </Link>
              </div>
            </div>
            )}
          </div>

          <div className="ml-auto mr-4">
            <Tooltip title={fragmentsTooltip}>
              <Link href="/account/transactions">
                <Stat
                  value={formatNumber(user?.balance || 0)}
                  currency="Fragments"
                  image={'/tokens/wyrmstone-fragment@200.png'}
                />
              </Link>
            </Tooltip>
          </div>

          {!isRaidsDetailPage && (
          <div className="ml-4 mr-4">
            <Tooltip title={orbsTooltip}>
              <Link href="/raids/shop">
                <Stat
                  value={formatNumber(user?.orbs_balance || 0)}
                  currency="Orbs"
                  image={'/tokens/orb.png'}
                />
              </Link>
            </Tooltip>
          </div>
          )}

          {!isRaidsDetailPage && walletData && (
          <div className="ml-4 mr-4 hidden md:block">
            <Tooltip title={wyrmstoneTooltip}>
              <Stat
                value={formatNumber(walletData?.coins || 0)}
                currency="$WYRM"
                image={'/tokens/wyrmstone@200.png'}
              >
                <button
                  onClick={refreshWallet}
                  type="button"
                  className="ml-2 flex items-center"
                >
                  <FontAwesomeIcon
                    icon={faRefresh}
                    className={`w-3 h-3 ${loading ? 'animate-spin' : ''}`}
                  />
                </button>
              </Stat>
            </Tooltip>
          </div>
          )}

          {!isRaidsDetailPage && (
          <div className="h-0 basis-full mt-3"></div>
          )}

          {!isRaidsDetailPage && (
          <div className="w-40 mr-4">
            {user && user.levelEnd && (
            <ProgressBar
              className="w-full mt-3"
              level={user?.level}
              label="Account level"
              barStart={user?.level >= 100 ? user?.xp : user?.levelStart}
              barCurrent={user?.xp}
              barEnd={user?.level >= 100 ? user?.xp : user?.levelEnd}
              hideProgressWhenFull={true}
              barSuffix="XP"
              height="h-4"
            />
            )}
          </div>
          )}

          <div className="ml-auto grow-0 flex items-center">
            {user && !user.activeShrineBuff && (
              <div className="bg-tertiary p-2 py-0 leading-4 rounded relative flex items-center h-8">
                <NoneActiveShrineIcon />
              </div>
            )}
            {user && user.buffs && (
            <div className="bg-tertiary p-2 py-0 leading-4 rounded relative flex items-center ml-1 h-8">
              <span className="block text-xs text-raids-text uppercase md:pr-1">
                Buffs
              </span>
              <BuffIcon
                buffs={user.buffs}
                positionClasses="relative block"
                small={true}
              />
              {user.canCraftBuff && (
              <Link href="/raids/craft">
                <span className="ml-0 border border-primary rounded-xs w-4 h-4 leading-0 flex items-center justify-center md:ml-2">
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="w-3 h-3 text-white"
                  />
                </span>
              </Link>
              )}
            </div>
            )}
          </div>
        </div>

        <Snow
          width={60}
          top={-5}
          right={193}
        />
      </div>

      <div className="hidden w-full lg:block">
        <div className="flex w-full items-center justify-center">
          <UserBar
            user={user}
            usernameFixedWidth={true}
          />

          <div className="flex border border-primary rounded bg-dark px-2 h-[60px] items-center box-content ml-2 relative">
            <Snow
              width={60}
              top={-6}
            />
            <Tooltip
              title={fragmentsTooltip}
            >
              <Link href="/account/transactions">
                <Stat
                  value={formatNumber(user?.balance || 0)}
                  currency="Fragments"
                  image={'/tokens/wyrmstone-fragment@200.png'}
                />
              </Link>
            </Tooltip>

            <Tooltip title={orbsTooltip}>
              <Link href="/account/transactions/orbs">
                <Stat
                  value={formatNumber(user?.orbs_balance || 0)}
                  currency="Orbs"
                  image={'/tokens/orb.png'}
                />
              </Link>
            </Tooltip>

            <div className="-ml-1 flex h-full items-center mr-2">
              <Link href="/raids/shop">
                <span className="border border-primary rounded-xs w-4 h-4 leading-0 flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="w-3 h-3 text-white"
                  />
                </span>
              </Link>
            </div>

            {walletData && (
              <Tooltip title={wyrmstoneTooltip}>
                <Stat
                  value={formatNumber(walletData?.coins || 0)}
                  currency="$WYRM"
                  image={'/tokens/wyrmstone@200.png'}
                />
              </Tooltip>
            )}

            {walletData && (
              <Tooltip title="Refresh the amount of $WYRM in your connected Cardano wallet">
                <button
                  onClick={refreshWallet}
                  type="button"
                  className="-ml-1 mr-2 flex items-center"
                >
                  <FontAwesomeIcon
                    icon={faRefresh}
                    className={`w-4 h-4 text-primary ${loading ? 'animate-spin' : ''}`}
                  />
                </button>
              </Tooltip>
            )}
          </div>

          <div className="ml-auto flex">
            <div className="flex border border-primary rounded bg-dark w-[60px] h-[60px] justify-center items-center box-content">
              <AudioPlayer route={router.asPath} />
            </div>

            {user && (
            <div className="flex border border-primary rounded bg-dark w-[60px] h-[60px] justify-center items-center box-content ml-2">
              <WalletSelect />
            </div>
            )}

            <div className="flex border border-primary rounded bg-dark w-[60px] h-[60px] justify-center items-center box-content ml-2">
              <Notifications />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
