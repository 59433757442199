import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackpack } from '@fortawesome/pro-duotone-svg-icons'
import useAuth from 'hooks/useAuth'
import useApi from 'hooks/useApi'
import { Tooltip } from 'react-tippy'

export default function TempBagToggle () {
  const [bagOpen, setBagOpen] = useState(false)

  const handleOpen = () => {
    setBagOpen(!bagOpen)
  }
  const user = useAuth()

  const uri = user ? '/api/user-bag-items/me' : null
  const { data } = useApi(uri, { refreshInterval: 1000 * 30 })
  const items = data && data.items ? data.items : []

  return (
    <>
      <div
        className="absolute left-3 bottom-3 hidden lg:block"
      >
        <button
          type="button"
          onClick={handleOpen}
          className="bg-dark rounded p-3 z-30 absolute left-6 bottom-4 border border-primary"
        >
          <FontAwesomeIcon
            icon={faBackpack}
            className="w-6 h-6 text-white hover:text-primary"
          />
        </button>
      </div>

      {bagOpen && (
        <div className="absolute bottom-24 left-9 w-[300px] h-[172px] rounded bg-dark p-3 z-40 border border-primary">
          <div className="font-bold mb-2">
            My bag
          </div>
          <div className="grid grid-cols-5 gap-2">
            {items && items.length > 0 && (
              <>
                {items.map((item, i) => (
                  <div
                    className="bg-tertiary rounded-sm aspect-square"
                    key={`bag_item_${i}`}
                  >
                    <Tooltip
                      html={<div className="text-left text-base">
                        <div className="font-bold">
                          {item.name}
                        </div>
                        <div className="text-raids-text font-bold text-sm">
                          Common
                        </div>
                        <div className="my-2">
                          {item.description}
                        </div>
                        <div className="text-xs italic mt-1 text-raids-text">
                          This item can be used in Ale & Axes - Beyond God&apos;s Trench
                        </div>
                      </div>}
                    >
                      <img
                        src={`/temp-items/${item.identifier}.webp`}
                        width={300}
                        height={300}
                        alt={item.name}
                      />
                    </Tooltip>
                  </div>
                ))}
              </>
            )}
            {Array.from(Array(10 - items.length)).map((k, i) => (
              <div
                className="bg-tertiary rounded-sm aspect-square"
                key={`bag_item_${i}`}
              >
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}
