export default function RaidOverlay ({ children, onClose }) {
  return (
    <div
      className="fixed z-40 top-0 right-0 bottom-0 left-0 flex items-center justify-center"
    >
      <div
        className="fixed z-40 top-0 right-0 bottom-0 left-0 bg-black bg-opacity-80"
        onClick={onClose}
      ></div>
      <div className="fixed z-50 flex justify-center items-center h-full">
        <div className="text-center">
          {children}
        </div>
      </div>
    </div>
  )
}
